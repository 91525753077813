// 自己の情報の取得

import api from './api'

const path = '/me'

export default (token = null) => {
  return api({
    url: path,
    auth: true,
    token,
  })
}
