// 受講者用のトップページ

<template lang="pug">
.student-top
  header
    h1
      span {{ studentName }}
      | さんのマイページ

  main

    el-tabs.tabs(type='card', v-model='currentTab')
      el-tab-pane(label='メッセージの一覧')
      el-tab-pane(label='アンケートの一覧')

    //- メッセージ
    article.messages(v-if='currentTab === "0"')
      p.no-message(v-if='messages.length === 0')
        | メッセージはありません。
      ul(v-else)
        li(v-for='item in messages', :class='{ unread: !item.isRead }')
          a(@click='clickMessage(item.id)')
            .title
              el-tag.tag(v-if='!item.isRead', size='mini', type='danger') 未読
              span.title {{ item.title }}
            .datetime {{ item.sentAt | jpDateTime }}

    //- アンケート
    article.surveys(v-if='currentTab === "1"')
      p.no-surveys(v-if='surveys.length === 0')
        | アンケートはありません。
      ul(v-else)
        li(v-for='item in surveys', :class='{ "not-answered": !item.answerId, finished: !item.isActive }')
          a(@click='clickSurvey(item)')
            el-row(type='flex', align='middle', :gutter='20')
              el-col.status(:span='5')
                el-tag(:type='item.isActive ? "" : "info"') {{ item.isActive ? '受付中' : '終了' }}
              el-col(:span='14')
                .title {{ item.title }}
                .end {{ item.end | jpDate }}まで
              el-col.condition(:span='5')
                p {{ item.answerId ? '回答済' : '未回答' }}

  message-detail-dialog(:visible.sync='messageDetailDialogVisible', :message-id='messageId', :token='token')
  confirm-answer-dialog(:visible.sync='confirmAnswerDialogVisible', :answer='answer', :items='items', only-confirm)
</template>

<script>
import getMyMessageListApi from '@/api/get-my-message-list'
import getTokenByAccessKey from '@/api/get-token-by-access-key'
import getSurveyListApi from '@/api/get-survey-list'
import getSurveyApi from '@/api/get-survey'
import getMeApi from '@/api/get-me'

import MessageDetailDialog from '@/dialogs/message-detail-dialog'
import ConfirmAnswerDialog from '@/dialogs/confirm-answer-dialog'

export default {
  name: 'StudentTop',

  components: {
    MessageDetailDialog,
    ConfirmAnswerDialog,
  },

  data() {
    return {
      currentTab: null,
      token: null,
      messages: [],
      surveys: [],
      messageId: null,
      messageDetailDialogVisible: false,
      confirmAnswerDialogVisible: false,
      answer: null,
      items: null,
      studentName: '',
    }
  },

  async created() {
    const response = await getTokenByAccessKey(this.accessKey)
    if (!response.ok) return
    this.token = response.payload.token
    this.currentTab = this.initialTab
    this.initMyInfo()
    this.initMessages()
    this.initSurveys()
  },

  computed: {
    accessKey() {
      return this.$route.params.key
    },
    // ページを開いたときのタブ
    initialTab() {
      return this.$route.query.initialTab === 'survey' ? '1' : '0'
    },
  },

  methods: {
    async initMyInfo() {
      if (!this.token) return
      const response = await getMeApi(this.token)
      this.studentName = response.payload.name
    },

    async initMessages() {
      if (!this.token) return
      const response = await getMyMessageListApi(this.token)
      this.messages = response.payload.items
    },

    async initSurveys() {
      if (!this.token) return
      const response = await getSurveyListApi(this.token)
      this.surveys = response.payload.items
    },

    clickMessage(id) {
      // this.messageの該当メッセージを既読扱いとすることで、
      // 見た目上既読とする(この時点ではDBには反映されていない)
      const target = this.messages.find((item) => item.id === id)
      if (target) target.isRead = true
      this.messageId = id
      this.messageDetailDialogVisible = true
    },

    async clickSurvey(item) {
      // 期間終了
      if (!item.isActive) {
        // なにもしない
      } else if (item.answerId) {
        // 回答のIDがある = 回答済み
        const response = await getSurveyApi(item.id, this.token)
        if (!response.ok) return
        this.answer = response.payload.answer
        this.items = response.payload.form
        this.confirmAnswerDialogVisible = true
      } else {
        this.$router.push(`/s/${this.accessKey}/surveys/${item.id}`)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.student-top
  max-width: 800px
  margin-left: auto
  margin-right: auto

  header

    h1
      font-size: 0.9rem
      color: $grey
      span
        font-size: 1.2rem
        color: $grey-dark
      padding-bottom: 0.2rem

  main
    .tabs
      margin-top: 1rem

    .messages
      ul
        border-top: 1px solid $grey-lighter
        list-style-type: none
        li
          border-bottom: 1px solid $grey-lighter
          a
            display: block
            padding: 0.8rem 0.5rem
            color: $grey-dark
            cursor: pointer
            text-decoration: none
            &:hover
              background: #fffeec
            .title
              .tag
                margin-right: 0.5rem
              span.title
                font-size: 1rem
            .datetime
              font-size: 0.85rem
              margin-top: 0.3rem
              font-weight: bold
              color: darken($color-1, 15%)
          &.unread
            span.title
              font-weight: bold

    .surveys
      ul
        border-top: 1px solid $grey-lighter
        list-style-type: none
        li
          border-bottom: 1px solid $grey-lighter
          a
            display: block
            padding: 0.8rem 0.5rem
            color: $grey-dark
            cursor: pointer
            text-decoration: none
            &:hover
              background: #fffeec
            .status
              text-align: center
            .title
              font-weight: bold
            .end
              margin-top: 0.2rem
              font-size: 0.85rem
              color: darken($color-1, 20%)
            .condition
              text-align: center
              p
                color: #0068b7
                font-weight: bold
          &.not-answered
            .condition
              p
                color: #b70000
          &.finished
            background: $white-ter
</style>
