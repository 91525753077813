// アクセスキーからJWT取得

import api from './api'

const path = '/token-by-access-key'

export default (accessKey) => {
  return api({
    method: 'post',
    url: path,
    params: { accessKey },
  })
}
