// 回答内容確認用コンポーネント

<template lang="pug">
.survey-answer-confirmation

  ul
    li(v-for='item in answerable')

      .title {{ item.caption }}

      .answer
        //- １次元の表
        table(v-if='item.type === "table"')
          thead
            tr
              th(v-for='col in item.options') {{ col }}
          tbody
            tr
              td(v-for='value in getAnswer(item)') {{ value }}

        //- マトリクス
        table(v-else-if='item.type === "matrix"')
          thead
            tr
              th
              th(v-for='col in item.options') {{ col }}
          tbody
            tr(v-for='row, i in item.rows')
              th {{ row }}
              td(v-for='j in item.options.length') {{ getAnswer(item)[item.options.length * i + j - 1] }}

        //- 複数行
        pre(v-else-if='item.type === "multiline"') {{ getAnswer(item) }}

        //- それ以外
        p(v-else) {{ getAnswer(item) }}
</template>

<script>
// 回答可能な要素
const answerableTypes = [
  'line',
  'number',
  'multiline',
  'single',
  'multiple',
  'name',
  'kana',
  'table',
  'matrix',
  'org',
]

export default {
  name: 'SurveyAnswerConfirmation',

  props: {
    answers: {
      type: Object,
    },
    formItems: {
      type: Array,
    },
  },

  computed: {
    // 回答可能な要素(見出し等でない)
    answerable() {
      if (!this.formItems) return []
      return this.formItems.filter((item) => answerableTypes.indexOf(item.type) !== -1)
    },
  },

  methods: {
    getAnswer(item) {
      const answer = this.answers[item.id]
      if (item.type === 'multiple') {
        if (!answer || !answer.length) return '(選択なし)'
        else return answer.join('、 ')
      } else if (item.type === 'name' || item.type === 'kana') {
        if (!answer || answer.length !== 2) return ''
        else return answer.join(' ')
      } else if (item.type === 'table' || item.type === 'matrix') {
        return answer || []
      } else if (item.type === 'number') {
        return answer
      } else {
        return answer || ''
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.survey-answer-confirmation
  ul
    list-style-type: none
    li
      &:nth-child(n+2)
        margin-top: 1.5rem
      .title
        font-size: 1rem
        font-color: $grey
        font-weight: bold
        border-bottom: 2px solid lighten($color-1, 30%)
        padding-left: 0.2rem
      .answer
        margin-top: 0.2rem
        margin-left: 0.2rem
        overflow-x: auto
        p, pre
          font-size: 1rem
          color: $black-ter
        table
          border-collapse: collapse
          font-size: 1rem
          margin-top: 0.5rem
          th, td
            border: 1px solid #aaa
            text-align: center
          th
            line-height: 1
            color: $grey-dark
            padding: 0.5rem 0.2rem
            font-weight: normal
            background: #ddd
            white-space: nowrap
          tbody
            th
              padding: 0.5rem 1rem
            td
              padding: 0.5rem
</style>
