// アンケートへの回答

import api from './api'

const path = '/surveys/{id}/answer'

export default (surveyId, answer, token = null) => {
  const url = path.replace('{id}', surveyId)
  return api({
    method: 'put',
    url,
    params: {
      answer,
    },
    auth: true,
    token,
  })
}
