// アンケート情報の取得

import api from './api'

const path = '/surveys/{id}'

export default (surveyId, token = null) => {
  const url = path.replace('{id}', surveyId)
  return api({
    url,
    auth: true,
    token,
  })
}
