// 回答内容確認ダイアログ

<template lang="pug">
el-dialog.confirm-answer-dialog(
  title='回答内容の確認',
  width='90%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)'
)
  article(v-if='ready')

    p(v-if='!onlyConfirm') 回答内容をご確認の上「送信する」を押してください。

    survey-answer-confirmation.answers(
      :answers='answer',
      :form-items='items'
    )

    .buttons(v-if='!onlyConfirm')
      el-button(
        type='primary',
        @click='register'
      ) 送信する
      el-button(
        @click='close()'
      ) 訂正する
    .buttons(v-else)
      el-button(
        @click='close()'
      ) 閉じる

</template>

<script>
import registerSurveyAnswerApi from '@/api/register-survey-answer'

import SurveyAnswerConfirmation from '@/components/survey-answer-confirmation'

export default {
  name: 'ConfirmAnswerDialog',

  components: {
    SurveyAnswerConfirmation,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    answer: {
      type: Object,
    },
    surveyId: {
      type: Number,
    },
    items: {
      type: Array,
    },
    token: {
      type: String,
    },
    onlyConfirm: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ready() {
      return this.visible && !!this.answer && !!this.items
    },
  },

  methods: {
    close(success) {
      if (success) this.$emit('ok')
      this.$emit('update:visible', false)
    },

    async register() {
      const response = await registerSurveyAnswerApi(this.surveyId, this.answer, this.token)
      if (!response.ok) {
        this.$alert('アンケートの送信に失敗しました', 'エラー', {
          type: 'error',
        })
        return
      }
      this.close(true)
    },
  },
}
</script>

<style lang="sass" scoped>
.confirm-answer-dialog
  article
    margin-top: -1rem
    .answers
      margin-top: 1rem
    .buttons
      margin-top: 2rem
</style>
